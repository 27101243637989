/** @jsx jsx */
import AccountContent from 'components/AccountContent'

import {jsx} from 'theme-ui'
import {PageProps} from 'gatsby'
import {PageContext} from 'gatsbyTypes'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'

type Props = PageProps<object, PageContext>

const AccountPage: React.FC<Props> = ({pageContext}) => {
  useAutoSelectLanguage(pageContext)
  return (
    <AccountContent
      title="Your Account"
      errorTitle="An error has occurred"
      errorText="We had an error while fetching the donations data, we will try to fix
      this problem. Please try again later or contact us if it persists."
      emptyText="Welcome, you don't have a donation yet? Please donate to see something here."
      WelcomeMessage={({firstName, lastName, email}) => (
        <span>
          Welcome {firstName} {lastName}, your email address is: {email}.
        </span>
      )}
      signOutText="Sign out"
      donationsTitle="Your Donations"
      totalText="You donated for a total of"
      donationsTabTitle="Donation history"
      subscriptionsTabTitle="Manage your recurring payments"
    />
  )
}

export default AccountPage
